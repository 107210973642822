// Import Core
import React from 'react';
import styled from 'styled-components';

/* Styled Components */
export const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: fit-content;
	padding: 13px 16px 12px;
	gap: 10px;
	border: 1px solid transparent;
	transition: all 0.3s ease-in-out;

	color: var(--white);
	background: var(--primary-500);

	&:hover {
		cursor: pointer;
		background: var(--primary-400);
	}
	&:active {
		background: var(--primary-600);
	}
	&:disabled {
		color: var(--neutral-500);
		background: var(--neutral-200);
		pointer-events: none;
		cursor: not-allowed;
	}

	&.large {
		padding: 15px 20px;
		gap: 16px;
	}
	&.small {
		padding: 10px 14px 9px;
		gap: 10px;
	}

	/* Dark Button */
	&.dark-btn {
		color: var(--white);
		background: var(--neutral-900);

		&:hover {
			background: var(--neutral-800);
		}
		&:active {
			background: var(--neutral-900);
		}
	}

	/* Light Button */
	&.light-btn {
		color: var(--neutral-900);
		background: var(--neutral-100);

		&:hover {
			background: var(--neutral-200);
		}
		&:active {
			background: var(--neutral-300);
		}
	}

	/* Outline Button */
	&.outline-btn {
		color: var(--white);
		background: transparent;
		border: 1px solid var(--white);

		&:hover {
			background: var(--neutral-50);
			border-color: transparent;
			color: var(--neutral-300);
		}
		&:active {
			color: var(--white);
			background: var(--neutral-200);
			border: 1px solid var(--white);
		}
		&:disabled {
			color: var(--neutral-500);
			background: transparent;
			border: 1px solid var(--neutral-300);
		}
	}

	/* Primary Outline Button */
	&.primary-outline-btn {
		color: var(--primary-500);
		background: transparent;
		border: 1px solid var(--primary-500);

		&:hover {
			color: var(--primary-400);
			border-color: var(--primary-400);
			background: transparent;
		}
		&:active {
			color: var(--primary-900);
			background: transparent;
			border: 1px solid var(--primary-900);
		}
		&:disabled {
			color: var(--neutral-500);
			background: transparent;
			border: 1px solid var(--neutral-300);
		}
	}

	/* White Button */
	&.white-btn {
		color: var(--primary-500);
		background: var(--white);
		border: 1px solid transparent;

		&:hover {
			background: var(--primary-500);
			color: var(--white);
			border: 1px solid var(--neutral-100);
			box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
		}
		&:active {
			border-color: transparent;
			background: var(--neutral-100);
		}
		&.large {
		}
		&.small {
		}
	}

	/* Text Button */
	&.text-btn {
		color: var(--neutral-900);
		text-decoration-line: underline;
		background: transparent;
		padding: 0;

		&:hover {
			color: var(--neutral-800);
		}
		&:active {
			color: var(--neutral-900);
			background: transparent;
		}
		&:disabled {
			background: transparent;
		}
		&.large {
			padding: 0;
		}
		&.small {
			padding: 0;
		}
	}

	/* Text - Dark Button */
	&.text-dark-btn {
		color: var(--primary-500);
		background: transparent;
		padding: 0;

		&:hover {
			color: var(--neutral-900);
			background: transparent;
		}
		&:active {
			color: var(--primary-500);
			background: transparent;
		}
		&:disabled {
			background: transparent;
		}
		&.large {
			padding: 0;
		}
		&.small {
			padding: 0;
		}
	}
`;

/* Render Component */
const ButtonComponent = (props) => {
	return (
		<Button
			// className={generateClassName(props)}
			// onMouseEnter={props.onMouseEnter}
			// onMouseLeave={props.onMouseLeave}
			// onClick={props.onClick}
			disabled={props.disabled}>
			{props.children}
		</Button>
	);
};
export default ButtonComponent;
